<template>
  <div class="not-found">
    <div class="msg-box">
      <h2 class="animate__animated animate__fadeInUp">
        {{ $t("common.messages.dontHavePermission") }}
      </h2>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
@import "../assets/scss/_variables";
@import "../assets/scss/_mixins";

.msg-box {
  max-width: 350px;
  margin: 50px auto;

  h1 {
    @include text-bordered-shadow();
  }
}
</style>
