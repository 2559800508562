import type { GetOrderDetailParameterRequest } from "../types";

export default {
    authorization: {
        roles: "auth/v1/roles",
        userRole: "auth/v1/user-roles",
        userList: "auth/v1/user-roles/for-user-list",
        roleDetail: (params: any) => `auth/v1/roles/${params.roleId}`,
        resetRole: (roleNumber: number) =>
            `auth/v1/role-authorities/${roleNumber}/reset`,
        roleAuthority: "auth/v1/role-authorities",
        groups: "auth/v1/groups",
        menus: "auth/v1/menus",
        menuLevel: "auth/v1/menus/tree-view",
        menuList: "auth/v1/menus/menu-list",
        userMenu: "auth/v1/role-authorities/user-menu",
        changePassword: "auth/v1/users/update/password",
        changePasswordWithoutToken: (userId: string) =>
            `auth/v1/users/open/${userId}/password/change`,
        passwordPolicy: "auth/v1/open/password-policy",
        resetPasswordToken: "auth/v1/users/open/reset-password/request",
        enterNewPassword: "auth/v1/users/open/reset-password/confirm",
        updateUserInfo: (userId: string) => `auth/v1/users/${userId}`,
    },
    procurement: {
        documentNumber: "common/v1/doc-numbers",
    },
    common: {
        login: "auth/v1/open/login",
        //login: "https://api-dev.hnv.vvnst.com/api/auth/v1/open/login",
        loginHOS: "auth/v1/open/login/sso/hos",
        loginHMM: "auth/v1/open/login/sso/hmm",
        refreshToken: "auth/v1/open/refresh-token",
        searchID: (businessRegistrationNumber: string) =>
            `auth/v1/users/open/${businessRegistrationNumber}`,
        changePassword: (userId: string) =>
            `auth/v1/users/open/${userId}/password`,
        companyProfile: (companyCode: string) =>
            `auth/v1/companies/${companyCode}`,
        portCodes: "common/v1/port-codes",
        portCodesV2: "common/v2/port-codes",
        commonCodes: "common/v1/common-codes",
        recordTypes: "common/v1/record-types",
        addressBookGroups: "common/v1/address-book-groups",
        nationList: "common/v1/nations",
        addressBooks: "common/v1/address-book-details",
        updateAddressBookGroup: (data: any) =>
            `common/v1/address-book-groups/${data.emailGroupCode}`,
        updateAddressBookDetails: (data: any) =>
            `common/v1/address-book-details/${data.emailAddress}`,
        deleteAddressBookGroup: (params: any) =>
            `common/v1/address-book-groups/${params.emailGroupCode}`,
        deleteAddressDetails: (params: any) =>
            `common/v1/address-book-details/${params.emailAddress}/address-book-groups/${params.groupCode}`,
        checkMailExists: (params: any) =>
            `common/v1/address-book-details/${params.emailAddress}/existing`,

        // File
        files: "file/v2/files",
        downloadFile: (sequence: number) =>
            `file/v1/files/${sequence}/downloads`,
        downloadFileV2: (sequence: number, vmsFileId: number) =>
            `file/v2/files/${sequence}/downloads/${vmsFileId}`,
        uploadFileToS3: "file/v2/files/upload/s3",
        uploadFileToLocal: "file/v2/files/upload/local",
        ckUploadFileToS3: "file/v1/files/ck-upload/s3",
        ckUploadFileToLocal: "file/v1/files/ck-upload/local",
        ckUploadFileToS3V2: "file/v2/files/ck-upload/s3",
        kainosMessage: "common/v1/common-messages/open/by-group",
        owner: "common/v1/companies/owner",
        shipManager: "common/v1/companies/ship-management",
        fleet: "common/v1/address-book-groups/fleet",
        groups: "common/v1/email-groups",
        followUp: "common/v1/task-managements/follow-up",
        picUser: "auth/v1/users",
        groupDetails: "common/v1/email-group-details",

        // Common Code
        vesselCodes: "common/v1/vessel-codes",
        vesselParticular: "common/v1/vessel-particular/admin",
        vesselSystemMaster: "common/v1/vessel-system-master/admin",
        portCodeAdmin: "common/v1/admin/port-codes",
        report: "common/v1/reports/open/helper",
        taskManagement: "common/v1/task-managements",
        company: "common/v1/companies",
        companyCode: (companyCode: string) =>
            `common/v1/companies/${companyCode}`,
        departmentCodes: "common/v1/admin/department-codes",
        updateDepartmentCodes: (departmentCodes: string) =>
            `common/v1/admin/department-codes/${departmentCodes}`,
        // Supplier Code
        suppliersV2: "common/v2/suppliers",
        supplyItems: "common/v1/suppliers/supply-items",
        adminConfiguration: "admin-config/v1/configs",
        boardManagement: "board-management/v1/board-managements",
    },
    epro: {
        // DashBoard
        dashboard: {
            lastestInfo: {
                quotation: "epro/v1/dashboard/lastestInfo/quotationList",
                others: "epro/v1/dashboard/lastestInfo/othersList",
            },
            newEBid: "epro/v1/dashboard/newEBidList",
            progressCount: "epro/v1/dashboard/progressCount",
        },
        eproUserInfo: "epro/v1/commons/epro-user-info",
        //bidding
        bidding: (params: { biddingTypeCode: string }) =>
            `epro/v1/biddings/${params.biddingTypeCode}`,
        biddingDetail: (params: {
            biddingTypeCode: string;
            biddingNumber: string;
        }) =>
            `epro/v1/biddings/${params.biddingTypeCode}/${params.biddingNumber}/detail`,
        biddingSupplierDetail: (params: {
            biddingTypeCode: string;
            vendorBiddingNumber: string;
        }) =>
            `epro/v1/biddings/${params.biddingTypeCode}/${params.vendorBiddingNumber}/supplier-detail`,
        //biddingLog: "/epro/v1/biddings/log",
        biddingLog: (params: { biddingTypeCode: string }) =>
            `epro/v1/biddings/${params.biddingTypeCode}/log`,
        biddingSupplierApply: (params: {
            biddingTypeCode: string;
            biddingNumber: string;
        }) =>
            `epro/v1/biddings/${params.biddingTypeCode}/${params.biddingNumber}/supplier-apply`,
        biddingSupplierCancel: (params: {
            biddingTypeCode: string;
            vendorBiddingNumber: string;
        }) =>
            `epro/v1/biddings/${params.biddingTypeCode}/${params.vendorBiddingNumber}/supplier-cancel`,

        //invoice
        invoice: "epro/v1/invoice",
        invoiceTargets: "epro/v1/invoice/target",
        invoiceSend: "epro/v1/invoice/send",
        invoiceDetail: "epro/v1/invoice/detail",
        invoiceDetailPrint: "epro/v1/invoice/detail/print",

        // PaymentStatus
        paymentStatusSummary: "epro/v1/paymentstatus/summary",
        paymentStatusDetail: "epro/v1/paymentstatus/detail",

        // Claim List
        claimList: "epro/v1/claim",
        claimDetail: "epro/v1/claim/detail",
        claimDeliveryPlanForSave: "epro/v1/claim/delivery-plan/save",
        claimDeliveryPlanForSubmit: "epro/v1/claim/delivery-plan/submit",

        // Vessel Info
        vesselInformation: "epro/v1/vesselInfo/vesselInformation",

        //Quotation
        quotation: "/procurement/v1/quotation",
        quotationDetail: (params: any) =>
            `procurement/v1/quotation/${params.rfqNo}/${params.companyCode}/${params.kind}/detail`,
        updateQuotation: (params: any) =>
            `procurement/v1/quotation/${params.rfqNo}/${params.companyCode}/${params.kind}/update`,
        quotationDetailSubmit: (params: any) =>
            `procurement/v1/quotation/${params.rfqNo}/${params.companyCode}/${params.vesselCode}/${params.kind}/detail/submit`,
        quotationRemark: (params: any) =>
            `procurement/v1/quotation/${params.companyCode}/${params.vesselCode}/${params.documentNo}/${params.kind}/requisition-remark`,
        updateSupplierReadDate: (params: {
            rfqNo: string;
            companyCode: string;
            vesselCode: string;
            kind: string;
        }) =>
            `/procurement/v1/quotation/${params.rfqNo}/${params.companyCode}/${params.vesselCode}/${params.kind}/update/supplier-read-date`,
        erpNumber: "common/v1/erp-numbers",
        externalReferenceQuotation: "epro/v1/IFHNV4003/v1/external/reference1",
        quotationDetailExport:
            "procurement/v1/quotation/export/quotation-item/excel",

        //Order
        order: "/procurement/v1/order",
        orderDetail: (params: GetOrderDetailParameterRequest) =>
            `/procurement/v1/order/${params.poNo}/${params.companyCode}/${params.claimNo}/${params.kind}/detail`,
        updateOrderDetail: (params: GetOrderDetailParameterRequest) =>
            `/procurement/v1/order/${params.poNo}/${params.companyCode}/${params.claimNo}/${params.kind}/update`,
        getOrderByVesselCode: (params: {
            poNo: string;
            vesselCode: string;
            kind: string;
        }) =>
            `/procurement/v1/order/${params.poNo}/${params.vesselCode}/${params.kind}/detail`,
        orderDetailExport: "procurement/v1/order/export/order-item/excel",
        externalReferenceOrder: "epro/v1/IFHNVW4009/v1/external/reference1",

        //good receipt
        goodReceiptList: "epro/v1/IFMM4025/v1/external/reference1",
        goodReceiptDetail: "epro/v1/IFMM6025/v1/external/reference1",
    },
    boardManagement: {
        notice: "board-management/v1/board-managements/by-type",
    },
};
