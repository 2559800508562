<template>
  <el-dialog v-model="isVisible" class="!min-w-[850px] !max-w-[950px]" @close="handleClose">
    <template #header>
      <div class="custom-dialog-header">Profile</div>
    </template>
    <content-layout>
      <template #content-header>
        <div class="flex items-center justify-between">
          <span class="text-[18px] text-black font-bold">Company Information</span>
          <el-button type="secondary" size="large" @click="handleChangePassword">
            Change Password
          </el-button>
        </div>
      </template>
      <template #content>
        <div class="flex items-center">
          <template v-for="tab in tabs" :key="tab.id">
            <TabItem
              :title="tab.label"
              :is-action="activeTabId === tab.id"
              @click="handleClickTab(tab.id)"
            />
          </template>
        </div>
        <!-- Company Detail -->
        <CompanyDetail
          v-show="activeTabId === PROFILE_TAB_ID.COMPANY_DETAIL"
          :form="formCompanyDetail"
          :supply-items="supplyItems"
        />
        <!-- Personal Detail -->
        <PersonalDetail
          v-show="activeTabId === PROFILE_TAB_ID.PERSONAL_DETAIL"
          :form="formPersonalDetail"
        />
        <!-- Saleperson -->
        <Salesperson v-show="activeTabId === PROFILE_TAB_ID.SALES_PERSON" :form="formSalesPerson" />
      </template>
      <template #footer>
        <el-button type="secondary" @click="handleClose"
          >{{ $t('common.buttons.close') }}
        </el-button>
      </template>
    </content-layout>
  </el-dialog>
  <ChangePasswordPopup
    :visible="showChangePassword"
    :user-id="userId"
    @close="showChangePassword = false"
  />
</template>

<script lang="ts" setup>
import { onMounted, reactive, ref, watch } from 'vue'
import { CACHE, getCookie } from 'shared'

import TabItem from 'ui/src/custom-components/TabItem.vue'

import { CommonService } from '@/services/commons'
import { useAuthStore } from '@/stores/auth'
import { CompanyDetailForm, DEFAULT_VALUE, PersonalDetailForm, PROFILE_TAB_ID } from '@/types'

import CompanyDetail from '../components/ProfileForms/CompanyDetail.vue'
import PersonalDetail from '../components/ProfileForms/PersonalDetail.vue'
import Salesperson from '../components/ProfileForms/Salesperson.vue'
import ChangePasswordPopup from './authentication/ChangePasswordPopup.vue'

const commonService = new CommonService()

const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
  },
})

// Emits
const emit = defineEmits(['submit', 'close'])

// Stores
const authStore = useAuthStore()

// Refs
const isVisible = ref<boolean>(false)
const showChangePassword = ref<boolean>(false)
const activeTabId = ref<string>(PROFILE_TAB_ID.COMPANY_DETAIL)
const userId = ref<string>('')
const supplyItems = ref(null)

const formCompanyDetail = reactive<CompanyDetailForm>({
  supplierCode: '',
  supplierName: '',
  business: DEFAULT_VALUE.EMPTY,
  businessType: DEFAULT_VALUE.EMPTY,
  businessKind: '',
  businessItem: '',
  certificateResident: '',
  nation: '',
  state: '',
  city: '',
  locationCode: '',
  zipCode: '',
  address: '',
  yearRevenue: '',
  totalEmployee: '',
  majorTradingPartner: '',
  supplierItem: {
    supplierItemFirst: DEFAULT_VALUE.EMPTY,
    supplierItemSecond: DEFAULT_VALUE.EMPTY,
    supplierItemThird: DEFAULT_VALUE.EMPTY,
  },
})

const formPersonalDetail = reactive<PersonalDetailForm>({
  representativeName: '',
  representativeNameLocal: '',
  residentRegistrationNo: '',
  representativeTel: '',
  representativeFax: '',
  representativeMobile: '',
  representativeEmail: '',
  supplierNameLocal: '',
  addressLocal: '',
})

const formSalesPerson = reactive({
  salesPersonNameFirst: '',
  salesPersonTelFirst: '',
  salesPersonFaxFirst: '',
  salesPersonMobileFirst: '',
  salesPersonEmailFirst: '',

  salesPersonNameSecond: '',
  salesPersonTelSecond: '',
  salesPersonFaxSecond: '',
  salesPersonMobileSecond: '',
  salesPersonEmailSecond: '',
})

// Watch
watch(
  () => props.visible,
  (newVal) => {
    isVisible.value = newVal
  }
)

// Constants
const tabs = [
  { id: 'companyDetail', label: 'Company Detail' },
  { id: 'personalDetail', label: 'Personal Detail' },
  { id: 'salesPerson', label: 'Salesperson' },
]

// Hooks
onMounted(() => {
  const userInfo = getCookie(CACHE.USER_INFO) ?? null
  userId.value = userInfo.id
  getCompanyProfile(userInfo.companyCode)
  getSupplyItems()
})

// Functions
const emitEvents = () => {
  emit('submit')
  emit('close')
}

const handleClose = () => {
  emitEvents()
}

const handleChangePassword = () => {
  showChangePassword.value = true
}

const handleClickTab = (tabId: string) => {
  if (tabId === activeTabId.value) {
    return
  }
  activeTabId.value = tabId
}

const getSupplyItems = async () => {
  const data = await commonService.getSupplyItems()
  supplyItems.value = data
}

const getCompanyProfile = async (companyCode: string) => {
  const data = await authStore.companyProfile(companyCode)
  if (data) {
    formCompanyDetail.supplierCode = data.supplierCode
    formCompanyDetail.supplierName = data.informationTradeName
    formCompanyDetail.business = data.tradeTypeCode
    formCompanyDetail.businessType = data.tradeCompanyCode
    formCompanyDetail.businessKind = data.businessKindName
    formCompanyDetail.businessItem = data.businessItemName
    formCompanyDetail.certificateResident = data.certificateResidenceNumber
    formCompanyDetail.nation = data.nationName
    formCompanyDetail.state = data.vmsStatusCode
    formCompanyDetail.city = data.cityName
    formCompanyDetail.locationCode = data.locationCode
    formCompanyDetail.zipCode = data.zipCode
    formCompanyDetail.address = `${data.firstAddress ?? ''} ${data.secondAddress ?? ''} ${
      data.thirdAddress ?? ''
    }`
    formCompanyDetail.yearRevenue = data.previousRevenueAmount
    formCompanyDetail.totalEmployee = data.totalEmployeeCount ?? 0
    formCompanyDetail.majorTradingPartner = data.majorTradingPartnerDescription
    formCompanyDetail.supplierItem.supplierItemFirst =
      data.supplyItemFirstGroupCode ?? DEFAULT_VALUE.EMPTY
    formCompanyDetail.supplierItem.supplierItemSecond =
      data.supplyItemSecondGroupCode ?? DEFAULT_VALUE.EMPTY
    formCompanyDetail.supplierItem.supplierItemThird =
      data.supplyItemThirdGroupCode ?? DEFAULT_VALUE.EMPTY

    formPersonalDetail.representativeName = data.residenceName
    formPersonalDetail.representativeNameLocal = data.residenceLocalName
    formPersonalDetail.residentRegistrationNo = data.residenceRegistrationNumber
    formPersonalDetail.representativeTel = data.residencePhoneNumber
    formPersonalDetail.representativeFax = data.residenceFaxNumber
    formPersonalDetail.representativeMobile = ''
    formPersonalDetail.representativeEmail = data.residenceEmailAddress
    formPersonalDetail.supplierNameLocal = data.tradeLocalName
    formPersonalDetail.addressLocal = data.addressLocalName

    formSalesPerson.salesPersonNameFirst = data.salesPersonFirstName
    formSalesPerson.salesPersonTelFirst = data.salesPersonFirstPhoneNumber
    formSalesPerson.salesPersonFaxFirst = data.faxFirstNumber
    formSalesPerson.salesPersonMobileFirst = data.salesPersonFirstMobileNumber
    formSalesPerson.salesPersonEmailFirst = data.salesPersonFirstEmailAddress
    formSalesPerson.salesPersonNameSecond = data.salesPersonSecondName
    formSalesPerson.salesPersonTelSecond = data.salesPersonSecondPhoneNumber
    formSalesPerson.salesPersonFaxSecond = data.faxSecondNumber
    formSalesPerson.salesPersonMobileSecond = data.salesPersonSecondMobileNumber
    formSalesPerson.salesPersonEmailSecond = data.salesPersonSecondEmailAddress
  }
}
</script>
