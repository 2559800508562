/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig } from 'axios'
import { ElNotification } from 'element-plus'
import { isEmpty, isObject, omit } from 'lodash'
import { baseURL, getEnv, jwt } from 'shared/utils'
import { getCookie } from 'shared/utils/cache'
import { CACHE, KAINOS_RESPONSE_STATUS_TYPE, LANG_TP_CD } from 'shared/utils/constants'

import { isVessel } from '@/shared/constants'
import {
  BooleanValue,
  FileRequestApiOptions,
  languageTypeCode,
  RequestApiOptions,
  type AttachmentFile,
} from '@/types'

import { handleFileResponse, makeDownloadableUrl } from './file-api-client'

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  noCleanProps?: boolean
  isDisplayErr?: boolean
}

type PropsType = object | Array<any> | null

export const instance = axios.create({
  baseURL,
  timeout: 60000,
})

//request interceptor
instance.interceptors.request.use(
  (config) => {
    if (config.method !== 'put') {
      config.data = cleanProps(config.data)
      config.params = cleanProps(config.params)
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// response interceptor
instance.interceptors.response.use(
  (response) => {
    // Check response status and handle accordingly
    if (
      response.data.common &&
      response.data.common.status === KAINOS_RESPONSE_STATUS_TYPE.SUCCESS
    ) {
      return response
    } else if (response.data.common) {
      return Promise.reject(response.data.common.message)
    } else {
      return response
    }
  },
  (error) => {
    // Handle error responses
    const errorMessage = error?.response?.data?.common?.message ?? 'Unknown error occurred'
    ElNotification({
      title: 'Error',
      message: errorMessage,
      type: 'error',
      position: 'top-left',
    })
    return Promise.reject(error)
  }
)

const cleanProps = (payload: PropsType) => {
  for (const propName in payload) {
    const checkNull: boolean =
      payload[propName as keyof typeof payload] === null ||
      payload[propName as keyof typeof payload] === undefined ||
      payload[propName as keyof typeof payload] === '' ||
      (isObject(payload[propName as keyof typeof payload]) &&
        isEmpty(payload[propName as keyof typeof payload]))
    if (checkNull) {
      delete payload[propName as keyof typeof payload]
    }
  }
  return payload
}

export const request = async (
  method: string,
  path: string,
  requestHeaders: PropsType = null,
  params: PropsType = null,
  data: PropsType = null,
  isSecure: boolean = true,
  responseType: any = 'json'
) => {
  try {
    const headers: any = {
      'Content-Type': 'application/json',
      ...(requestHeaders || {}),
      'Lang-Type-Code':
        getCookie(CACHE.LOCALE) === languageTypeCode.KR ? LANG_TP_CD.KR : LANG_TP_CD.EN,
      'Is-Vessel': isVessel ? BooleanValue.Yes : BooleanValue.No,
    }
    handleAddCanaryTokenHeader(headers)
    if (isSecure) {
      const token = await jwt()
      if (token) {
        headers.Authorization = `Bearer ${token}`
      }
    }

    const response = await instance.request({
      method,
      url: path,
      data,
      params,
      headers,
      responseType,
      paramsSerializer: (params) => {
        return new URLSearchParams(params).toString()
      },
    })
    return response.data
  } catch (error) {
    console.log('error', error)
    throw error
  }
}

export const request1 = async (method: string, path: string, options: RequestApiOptions = {}) => {
  try {
    const headers: any = {
      'Content-Type': 'application/json',
      ...(options.requestHeaders || {}),
      'Lang-Type-Code':
        getCookie(CACHE.LOCALE) === languageTypeCode.KR ? LANG_TP_CD.KR : LANG_TP_CD.EN,
      'Is-Vessel': BooleanValue.No,
    }
    handleAddCanaryTokenHeader(headers)
    if (options.isSecure === undefined || options.isSecure) {
      const token = await jwt()
      if (token) {
        headers.Authorization = `Bearer ${token}`
      }
    }

    const config: CustomAxiosRequestConfig = {
      method,
      url: path,
      headers,
      timeout: options.timeout || 60000,
      ...omit(options, ['requestHeaders', 'isSecure']),
      paramsSerializer: (params) => {
        return new URLSearchParams(params).toString()
      },
    }
    const response = await instance.request(config)
    return response.data
  } catch (error) {
    console.log('error', error)
    throw error
  }
}

const handleAddCanaryTokenHeader = (headers: any) => {
  const userInfo = getCookie(CACHE.USER_INFO)
  if (userInfo?.canaryFlag) {
    const canaryToken = getEnv({ name: 'VITE_CANARY_TOKEN' })
    if (canaryToken !== '') {
      headers['x-jwt-assertion'] = `Bearer ${canaryToken}`
    }
  }
}

export const reqPOSTNoCleanProps = async (
  path: string,
  data: PropsType = null,
  options: RequestApiOptions = {}
) => {
  return request1('POST', path, {
    ...options,
    data,
    noCleanProps: true,
  })
}

export const reqGET = async (
  path: string,
  params: PropsType = null,
  requestHeaders: PropsType = null,
  isSecure: boolean = true
) => {
  return request('GET', path, requestHeaders, params, null, isSecure)
}

export const reqPOST = async (
  path: string,
  data: PropsType = null,
  params: PropsType = null,
  requestHeaders: PropsType = null,
  isSecure: boolean = true
) => {
  return request('POST', path, requestHeaders, params, data, isSecure)
}

export const reqPOSTv2 = async (
  path: string,
  data: PropsType = null,
  options: RequestApiOptions = {}
) => {
  return request('POST', path, {
    ...options,
    data,
  })
}

export const reqPUT = async (
  path: string,
  data: PropsType = null,
  params: PropsType = null,
  requestHeaders: PropsType = null,
  isSecure: boolean = true
) => {
  return request('PUT', path, requestHeaders, params, data, isSecure)
}

export const reqDELETE = async (
  path: string,
  data: PropsType = null,
  requestHeaders: PropsType = null,
  isSecure: boolean = true
) => {
  return request('delete', path, requestHeaders, null, data, isSecure)
}

export const reqPATCH = async (
  path: string,
  data: PropsType = null,
  params: PropsType = null,
  requestHeaders: PropsType = null,
  isSecure: boolean = true
) => {
  return request('patch', path, requestHeaders, params, data, isSecure)
}

export const reqHEAD = async (
  path: string,
  params: PropsType = null,
  requestHeaders: PropsType = null,
  isSecure: boolean = true
) => {
  return request('head', path, requestHeaders, params, null, isSecure)
}

export const reqFile = async (
  path: string,
  file: AttachmentFile,
  isOpen: boolean = false,
  saveAs: boolean = false,
  inside: boolean = false,
  isSecure: boolean = true,
  params: PropsType = null,
  requestHeaders: PropsType = null
) => {
  return handleFileResponse(path, params, requestHeaders, isSecure, isOpen, saveAs, inside, file)
}

export const reqReportFile = async (
  path: string,
  params: PropsType = null,
  options: FileRequestApiOptions = {}
) => {
  return handleFileResponse(
    path,
    params,
    options.requestHeaders,
    options.isSecure,
    options.isOpen,
    options.saveAs,
    options.inside,
    options.file
  )
}

export const reqDownloadableFile = async (
  path: string,
  isSecure: boolean = true,
  params: PropsType = null,
  requestHeaders: PropsType = null
) => {
  return makeDownloadableUrl(path, params, requestHeaders, isSecure)
}
