import type { EFooter, ENavPos, EToolbar, EViewAnimation } from "./enums";
import type { ReadReportResponse } from "./response";

export * from "./enums";
export * from "./params";
export * from "./response";
export * from "./table";
export * from "./crew";

export interface BreadCrumb {
    text: string;
    isActive?: boolean;
}
export interface MetaData {
    layout: ENavPos;
    auth: boolean;
    searchable: boolean;
    tags: string[];
    title: string;
    breadCrumbs: BreadCrumb[];
    isSpecial?: boolean;
    readReport?: boolean;
}

export interface VesselItem {
    vesselCode: string;
    vesselName: string;
    disabled?: boolean;
}

export interface StateLayout {
    navPos: ENavPos | boolean | null;
    toolbar: EToolbar | boolean | null;
    footer: EFooter | boolean | null;
    boxed: boolean;
    roundedCorners: boolean;
    viewAnimation: EViewAnimation;
    quickPanel: boolean;
}

export interface State {
    layout: StateLayout;
    splashScreen: boolean;
    isLoading: boolean;
    readReport: ReadReportResponse;
}

export interface MenuItem {
    index: string;
    title: string;
    icon?: string;
    subItems?: SubMenuItem[];
}

export interface SubMenuItem {
    title: string;
    index?: string;
    subItems?: SubMenuItem[];
}

export interface MenuGroup {
    title: string;
    items: MenuItem[];
}

export interface QuickMenuItem {
    icon: string;
    name: string;
    actions: () => void;
}

export interface SupplierList {
    supplierCode: string;
    nationCode: string;
    supplierName: string;
    informationTradeName?: string;
}

export interface PortList {
    vmsCompanyCode: string;
    vesselCode: string;
    portCode: string;
    portName: string;
    nationCode: string;
}

export interface CommonCodeData {
    vmsCommonCode: string;
    vmsCommonName: string;
}

export interface CommonPagination {
    pageNumber: number;
    pageSize: number;
    totalPages: number;
    totalCount: number;
}

export enum BooleanValue {
    Yes = "Y",
    No = "N",
}

export interface RecordType {
    recordTypeSequence: number;
    recordTypeName: string;
    parentRecordTypeSequence: number;
    parentRecordTypeName: string;
    displayNumber: number;
}
export interface CommonCode {
    languageTypeCode: string;
    vmsCommonCode: string;
    vmsCommonName: string;
    vmsCommonGroupCode: string;
    additionContent1: string;
    additionContent2: string;
    additionContent3: string;
    additionContent4: string;
    additionContent5: string;
    createdUserId: string;
    createdTime: string;
    updatedUserId: string;
    updatedTime: string;
}

export interface AttachmentFile {
    vmsFileId: number;
    fileSequence: number;
    fileName: string;
    filePathUrl: string;
    fileSize: number;
    fileStoreInCode: string;
    fileTypeCode: string;
    fileType?: string;
}
export interface User {
    id: string;
    username: string | null;
    emailAddress: string | null;
    employeeKoreanName: string | null;
    employeeEnglishName: string | null;
    userId: string | null;
    userGradeCode: string | null;
    positionCode: string | null;
    vesselCode: string | null;
    emailGroupCode?: string;
    adminFlag?: string;
}

export interface MyDraftDetail {
    recordType: RecordType;
    docNo: string;
    approvalNumber: string;
    title: string;
    isAttachment: string;
    isUrgent: string;
    isConfidential: string;
    isReplyRequested: string;
    submitted: string;
    status: string;
    drafter: User;
    to: TransferMail[];
    approvalTime: string;
    cc: TransferMail[];
    reference: User[];
    approval: Array<User & { isApproved: boolean }>;
    body: string;
    signatureNumber: number;
    vmsFileId: number;
    isChanged?: boolean;
}

export interface TransferMail {
    name?: string;
    email: string;
    koreaName?: string;
    emailUserSourceCode?: string;
}

export enum languageTypeCode {
    KR = "kr",
    EN = "en",
}

export interface FromInbox {
    emailAddress?: string;
    koreanName?: string;
    englishName?: string;
    pic?: string;
}

export interface Document {
    allowDelete?: boolean;
    allowReceipt?: boolean;
    emailAddress: string;
    recordType: RecordType;
    docNo: string;
    title: string;
    transmitted: string;
    received: string;
    isAttachment: BooleanValue;
    isUrgent: BooleanValue;
    isConfidential: BooleanValue;
    isReplyRequested: BooleanValue;
    drafter: Drafter;
    from: FromInbox;
    to: TransferMail[];
    cc: TransferMail[];
    readFlag: BooleanValue;
    status?: string;
    department?: string;
    emailIdSequence?: number;
}

export interface Drafter {
    userId: string;
    userEmployeeNumber: string;
    userGradeCode: string;
    employeeEnglishName: string;
    employeeKoreanName: string;
    emailAddress: string;
    positionCode: string;
}

export interface Crew {
    userId: string;
    gradeCode: string;
    englishName: string;
    koreanName: string;
    groupName?: string;
    positionCode?: string;
    emailAddress?: string;
    isOfficer?: string;
    isSenior?: string;
    isJunior?: string;
    isKorean?: string;
    isForeign?: string;
    orderNo?: number;
}

export interface ApprovalStep {
    vmsEmpyNo: string;
    krnNm: string;
    engNm: string;
    postnCd: string;
    postnNm: string;
    emlAdds: string;
}

export interface DrafterParseType {
    positionCode?: string;
    employeeKoreanName?: string;
    employeeEnglishName?: string;
    koreanName?: string;
    englishName?: string;
}

export interface MailAddress {
    emailAddress: string;
    emailUserSourceCode: string;
}

export interface Group {
    vmsGroupSequence: number;
    groupName: string;
    publicFlag: string;
    numberOfAddress: number;
    emailAddress?: string;
    details?: GroupDetail[];
}

export interface GroupDetail {
    emailAddress?: string;
    mobilePhoneNumber?: string;
    officePhoneNumber?: string;
    picName?: string;
    vmsGroupSequence?: number;
}

export interface AddressGroupDetail {
    picName?: string;
    positionCode?: string;
    nationCode?: string;
    emailAddress?: string;
    emailGroupCode?: string;
    isLeaf?: boolean;
    dutyCode?: string;
    portCode?: string;
    groupKoreaName?: string;
    faxNumber?: string;
    userKoreaName?: string;
    groupName?: string;
    detailRemark?: string;
    emailAliasName?: string;
    mobilePhoneNumber?: string;
    officePhoneNumber?: string;
}

export interface AddressBookGroup {
    id?: string;
    children?: AddressBookGroup[];
    details?: AddressGroupDetail[];
    parentId?: string | null;
    emailGroupCode?: string;
    parentEmailGroupCode?: string | null;
    groupName?: string;
    groupKoreaName?: string;
    userSortDescription?: string;
    groupEmailAddress?: string;
    vmsSourceTypeCode?: string;
    groupTypeCode?: string;
    vmsCompanyCode?: string;
    isLeaf?: boolean;
    isRoot?: boolean;
    label?: string;
    allowDelete?: boolean;
    vesselCode?: string;
    leafNode?: boolean;
}

export interface AddressEmailType
    extends AddressGroupDetail,
        AddressBookGroup,
        TransferMail {
    vmsGroupSequence?: number;
}

export interface AddressPayload {
    to: AddressEmailType[];
    cc: AddressEmailType[];
}

export interface FollowUpForm {
    title: string;
    taskContent: string;
    vmsPicUserId: string;
    dueYmd: string;
}

export interface UserPIC {
    id: string;
    email: string;
    employeeEnglishName: string;
    employeeKoreanName: string;
    type: string;
    vmsCompanyCode: string;
    vesselCode: string;
    positionCode: string;
    emailGroupCode: string;
}

export interface SearchVesselFilters {
    filterOwner: string;
    filterShipManager: string;
    filterFleet: string;
    isMyVesselOnly: boolean;
}

export interface VesselSelectorParams extends SearchVesselFilters {
    vesselCode: string;
}

export interface Owner {
    companyCode: string;
    shortCompanyName: string;
}
export interface ShipManager {
    companyCode: string;
    shortCompanyName: string;
}

export interface Fleet {
    emailGroupCode: string;
    groupName: string;
    groupKoreaName: string;
    userSortDescription: string;
}
export type PropsType = object | Array<any> | null;

export type FileRequestApiOptions = {
    requestHeaders?: PropsType;
    isSecure?: boolean;
    timeout?: number;
    fileType?: string;
    isOpen?: boolean;
    saveAs?: boolean;
    file?: AttachmentFile;
    fileName?: string;
    inside?: boolean;
    onProgress?: (progressEvent: any) => void;
};

export type RequestApiOptions = {
    requestHeaders?: PropsType;
    params?: PropsType;
    data?: PropsType;
    timeout?: number;
    isSecure?: boolean;
    noCleanProps?: boolean;
    isDisplayErr?: boolean;
};
